import { format } from "date-fns";
import {
  CommissionType,
  DepositType,
  TradeType,
  WithdrawType,
} from "../models/Transactions.model";
import { GridColDef } from "@mui/x-data-grid";

export const tradeColumns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "profitUsd", headerName: "Profit (USD)", width: 150 },
  { field: "profitPol", headerName: "Profit (POL)", width: 150 },
  { field: "oldBalance", headerName: "Old Balance", width: 170 },
  { field: "newBalance", headerName: "New Balance", width: 170 },
  { field: "oldCredit", headerName: "Old Credit", width: 150 },
  { field: "newCredit", headerName: "New Credit", width: 150 },
  {
    field: "created_at",
    headerName: "Created At",
    width: 180,
    valueGetter: (_: any, row: TradeType) =>
      row?.created_at
        ? format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss")
        : "",
  },
  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  // },
];

export const commissionColumns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "from", headerName: "Sender Telegram ID", width: 200 },
  { field: "amount", headerName: "Amount (USD)", width: 170 },
  { field: "level", headerName: "Level", width: 200 },
  {
    field: "isPaid",
    headerName: "Status",
    width: 150,
    valueGetter: (_: any, row: CommissionType) =>
      row?.isPaid ? "Success" : "Failed",
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 180,
    valueGetter: (_: any, row: CommissionType) =>
      row?.created_at
        ? format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss")
        : "",
  },
];

export const withdrawColumns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "address", headerName: "Withdraw addresss", width: 200 },
  { field: "network", headerName: "Network", width: 150 },
  { field: "currency", headerName: "Currency", width: 200 },
  { field: "amount", headerName: "Amount", width: 170 },
  { field: "hash", headerName: "Hash", width: 200 },
  { field: "status", headerName: "Status", width: 150 },
  {
    field: "created_at",
    headerName: "Created At",
    width: 180,
    valueGetter: (_: any, row: WithdrawType) =>
      row?.created_at
        ? format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss")
        : "",
  },
  {
    field: "remark",
    headerName: "Remark",
    width: 500,
    setCellProps: () => ({
      style: {
        maxWidth: "500px",
        wordWrap: "break-word",
        display: "inline-block",
      },
    }),
  },
];

export const depositColumns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "address_bsc", headerName: "BSC addresss", width: 200 },
  { field: "address_trc", headerName: "TRC addresss", width: 200 },
  { field: "network", headerName: "Network", width: 80 },
  { field: "currency", headerName: "Currency", width: 85 },
  { field: "requested_amount", headerName: "Requested Amount", width: 150 },
  { field: "actual_amount", headerName: "Actual Amount", width: 120 },
  { field: "hash", headerName: "Hash", width: 200 },
  { field: "status", headerName: "Status", width: 80 },
  { field: "remark", headerName: "Remark", width: 200 },
  {
    field: "created_at",
    headerName: "Created At",
    width: 180,
    valueGetter: (_: any, row: DepositType) =>
      row?.created_at
        ? format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss")
        : "",
  },
];

export const depositHomeColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 60 },
  { field: "telegram_id", headerName: "Telegram ID", width: 100 },

  { field: "address", headerName: "Sender Address", width: 380 },
  {
    field: "actual_amount",
    headerName: "Amount",
    type: "number",
    width: 80,
  },
  {
    field: "currency",
    headerName: "Currency",
  },
  {
    field: "network",
    headerName: "Network",
  },
  { field: "hash", headerName: "Hash", width: 380 },
  {
    field: "created_at",
    headerName: "Date",
    width: 180,
    valueGetter: (_, row) => {
      return format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss");
    },
  },
];

export const withdrawPendingColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 60 },
  { field: "telegram_id", headerName: "Telegram ID", width: 100 },
  { field: "address", headerName: "Address", width: 380 },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
    width: 80,
  },
  {
    field: "currency",
    headerName: "Currency",
  },
  {
    field: "network",
    headerName: "Network",
  },
  {
    field: "createdAt",
    headerName: "Date",
    width: 180,
    valueGetter: (_, row) => {
      return format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss");
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    valueGetter: (_, row) =>
      row.status === "success" ? "Completed" : "Pending",
  },
];

export const withdrawCompletedColumns: GridColDef[] = [
  // ...withdrawPendingColumns.filter((column) => column.headerName !== "Remark"),
  {
    field: "hash",
    headerName: "Transaction Hash",
    width: 550,
    renderCell: (params) => {
      const address: string = params.row.address;
      if (address.startsWith("0x")) {
        return params.row.status === "success" ? (
          <div className="hover:opacity-70 hover:text-red">
            <a
              href={`https://bscscan.com/tx/${params.value}`}
              target="_blank"
              rel="noreferrer"
            >
              {params.value}
            </a>
          </div>
        ) : null;
      } else {
        return params.row.status === "success" ? (
          <div className="hover:opacity-70 hover:text-red">
            <a
              href={`https://tronscan.org/#/transaction/${params.value}`}
              target="_blank"
              rel="noreferrer"
            >
              {params.value}
            </a>
          </div>
        ) : null;
      }
    },
  },
];

export const tradeHomeColumns: GridColDef[] = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "telegram_id", headerName: "Telegram ID", width: 150 },
  // {
  //   field: "balance",
  //   headerName: "Old Balance (POL)",
  //   width: 150,
  //   valueGetter: (params: any) => {
  //     console.log(params);
  //     return params?.old?.toFixed(2);
  //   },
  // },
  {
    field: "balance",
    headerName: "Balance (POL)",
    width: 120,
    valueGetter: (params: any) => params?.new?.toFixed(2),
  },
  // {
  //   field: "credit",
  //   headerName: "Old Credit",
  //   width: 150,
  //   valueGetter: (params: any) => params?.old?.toFixed(2),
  // },
  {
    field: "profit",
    headerName: "Profit (POL)",
    width: 100,
    valueGetter: (params: any) => params?.matic?.toFixed(2),
  },
  {
    field: "credit",
    headerName: "Credit Balance (USD)",
    width: 160,
    valueGetter: (params: any) => params?.new?.toFixed(2),
  },
  // {
  //   field: "profit",
  //   headerName: "Profit (USD)",
  //   width: 150,
  //   valueGetter: (params: any) => params?.usd?.toFixed(2),
  // },
  {
    field: "created_at",
    headerName: "Created At",
    width: 180,
    valueGetter: (params: any) => {
      return params ? format(new Date(params), "dd MMM yyyy, HH:mm:ss") : "";
    },
  },
];

export const commissionHomeColumns: GridColDef[] = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "from", headerName: "From", width: 180 },
  { field: "to", headerName: "To", width: 180 },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
    valueGetter: (params: any) => params?.toFixed(4),
  },
  {
    field: "commission_rate",
    headerName: "Commission Rate",
    width: 150,
    valueGetter: (params: any) => `${(params * 100).toFixed(2)}%`,
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 200,
    valueGetter: (params: any) =>
      params ? new Date(params).toLocaleString() : "",
  },
  { field: "level", headerName: "Level", width: 100 },
  { field: "tier", headerName: "Tier", width: 100 },
  {
    field: "isPaid",
    headerName: "Status",
    width: 120,
    valueGetter: (params: any) => (params ? "Paid" : "Pending"),
  },
];

export const paginationModel = { page: 0, pageSize: 5 };

export const dataGridProps = {
  initialState: { pagination: { page: 0, pageSize: 100 } },
  pageSizeOptions: [20, 50, 100, 200],
  sx: { border: 0 },
};
