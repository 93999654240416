import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DialogActionType = "APPROVE" | "REJECT" | null;

interface DialogState {
    isOpen: boolean;
    actionType: DialogActionType;
}

const initialState: DialogState = {
    isOpen: false,
    actionType: null,
};

const confirmPopupSlice = createSlice({
    name: "confirmpopup",
    initialState,
    reducers: {
        open: (
            state,
            action: PayloadAction<{ actionType: DialogActionType }>,
        ) => {
            state.isOpen = true;
            state.actionType = action.payload.actionType;
        },
        close: (state) => {
            state.isOpen = false;
            state.actionType = null;
        },
    },
});

// reject popup
// const onReject = (data: any) => {
//   const { id } = data;
//   dispatch(open(id));
//   getWithdraws();
// };

export const { open, close } = confirmPopupSlice.actions;
export default confirmPopupSlice.reducer;
