import { supabase } from "../supabase/supabaseClient";
import { handleLogout } from "./handleLogout";

export const handleAuth = async () => {
    const sessionToken = localStorage.getItem("sessionToken");
    const adminId = localStorage.getItem("adminId");

    if (!sessionToken || !adminId) {
        return false;
    }

    try {
        const { data: admin, error } = await supabase
            .from("admins")
            .select("*")
            .eq("id", adminId)
            .eq("session_token", sessionToken)
            .single();

        if (error || !admin || !admin.role) {
            return false;
        }

        // Check if session is expired
        if (new Date(admin.session_expiry) < new Date()) {
            handleLogout();
            return false;
        }

        return true;
    } catch (e) {
        return false;
    }
};
