import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../supabase/supabaseClient";
import { toast } from "react-toastify";
import { format } from "date-fns/format";
import {
  CommissionType,
  DepositType,
  TradeType,
  WithdrawType,
} from "../models/Transactions.model";
import { paginationModel } from "../components/UserTransactionColumns";
import DataTable from "../components/DataTable";
import {
  EdgeType,
  HierarchySummaryType,
  NodeType,
} from "../models/Hierarchy.model";
import Hierarchy from "../components/Hierarchy";
import DateCalendar from "../components/DateCalendar";
import ClipLoader from "react-spinners/ClipLoader";
import dagre from "dagre";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { InformationIcon } from "../components/Icons";

type UserDetailType = {
  telegram_id: string;
  username: string;
  balance: number;
  commission: number;
  referral_code: string;
  parent: string;
  created_at: Date;
  level: number;
  tier: number;
  license: Date;
  dailyProfit: number;
  dailyTrades: number;
  address_bsc: string;
  address_trc: string;
  pol_address: string;
  usdt_history: number;
  token_history: number;
  activated: boolean;
  ban: boolean;
  credits: number;
};

function UserDetail() {
  const role = localStorage.getItem("role") || "";
  const { telegramId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserDetailType>();
  const [tableType, setTableType] = useState("TRADE");
  const [trades, setTrades] = useState<
    CommissionType[] | TradeType[] | DepositType[] | WithdrawType[]
  >([]);
  const [profitUsd, setProfitUsd] = useState<number>(0);
  const [profitPol, setProfitPol] = useState<number>(0);
  // const [isLoading, setIsLoading] = useState(true);

  const [hierarchySummary, setHierarchySummary] =
    useState<HierarchySummaryType>({
      numberOfChild: 0,
      polBalance: 0,
      creditBalance: 0,
      commissionBalance: 0,
    });
  const [nodes, setNodes] = useState<NodeType[]>([]);
  const [edges, setEdges] = useState<EdgeType[]>([]);
  const [totalLeaderPayout, setTotalLeaderPayout] = useState<number>(0);
  const [userLeaderPayout, setUserLeaderPayout] = useState<number>(0);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [excludeUser, setExcludeUser] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [totalDepositPayout, setTotalDepositPayout] = useState<number>(0);
  const [totalProfitPayout, setTotalProfitPayout] = useState<number>(0);

  // return parent node's children
  const getChildren = async (telegramId: string) => {
    const { data: userData, error } = await supabase
      .from("Users")
      .select("child")
      .eq("telegram_id", telegramId)
      .single();

    if (error) {
      // toast.error(`Error getting child information with telegram ID: ${telegramId}`);
      return [];
    }
    return userData?.child || [];
  };

  // const calculateLeaderPayout = async (telegramId: string) => {
  //   let depositQuery = supabase
  //     .from("Deposits")
  //     .select("actual_amount")
  //     .eq("telegram_id", telegramId)
  //     .eq("currency", "USDT");

  //   let tradeQuery = supabase
  //     .from("Transaction")
  //     .select("profit")
  //     .eq("telegram_id", telegramId);

  //   if (startDate) {
  //     depositQuery = depositQuery.gte("created_at", startDate.toISOString());
  //     tradeQuery = tradeQuery.gte("created_at", startDate.toISOString());
  //   }
  //   if (endDate) {
  //     depositQuery = depositQuery.lte("created_at", endDate.toISOString());
  //     tradeQuery = tradeQuery.lte("created_at", endDate.toISOString());
  //   }
  //   const { data: depositData, error: depositError } = await depositQuery;

  //   const { data: tradeData, error: tradeError } = await tradeQuery;

  //   if (depositError || tradeError) {
  //     // toast.error("Error calculating Leader's payout!");
  //     return 0;
  //   }

  //   const totalDeposit =
  //     depositData?.reduce((acc, d) => acc + Number(d.actual_amount), 0) ?? 0;

  //   const totalProfit =
  //     tradeData?.reduce((acc, d) => acc + Number(d.profit.usd), 0) ?? 0;

  //   // if undefined return 0
  //   if (typeof totalDeposit !== "number" || typeof totalProfit !== "number") {
  //     // toast.error("Error calculating Leader's payout!");
  //     return 0;
  //   }

  //   return totalDeposit - totalProfit / 2;
  // };

  const calculateLeaderPayoutAndBalance = async (
    telegramIds: string[],
    fetchedNodes: NodeType[]
  ) => {
    let totalPolBalance = 0;
    let totalCreditBalance = 0;
    let totalCommissionBalance = 0;
    let totalLeaderPayout = 0;
    let totalDeposit = 0;
    let totalProfit = 0;

    let depositQuery = supabase
      .from("Deposits")
      .select("telegram_id, actual_amount")
      .in("telegram_id", telegramIds)
      .eq("currency", "USDT");

    let tradeQuery = supabase
      .from("Transaction")
      .select("telegram_id, profit")
      .in("telegram_id", telegramIds);

    let userQuery = await supabase
      .from("Users")
      .select("telegram_id, balance, credits, commission")
      .in("telegram_id", telegramIds);

    if (startDate) {
      depositQuery = depositQuery.gte("created_at", startDate.toISOString());
      tradeQuery = tradeQuery.gte("created_at", startDate.toISOString());
    }
    if (endDate) {
      depositQuery = depositQuery.lte("created_at", endDate.toISOString());
      tradeQuery = tradeQuery.lte("created_at", endDate.toISOString());
    }

    const [
      { data: depositData, error: depositError },
      { data: tradeData, error: tradeError },
      { data: userData, error: userError },
    ] = await Promise.all([depositQuery, tradeQuery, userQuery]);

    if (depositError || tradeError || userError) {
      toast.error("Error calculationg leader payout");
      return {
        totalPolBalance: 0,
        totalCreditBalance: 0,
        totalCommissionBalance: 0,
      };
    }

    const sumDepositData =
      depositData?.reduce<Record<string, number>>((acc, data) => {
        acc[data.telegram_id] =
          (acc[data.telegram_id] || 0) + Number(data.actual_amount);
        return acc;
      }, {}) ?? {};

    const sumProfitData =
      tradeData?.reduce<Record<string, number>>((acc, data) => {
        acc[data.telegram_id] =
          (acc[data.telegram_id] || 0) + Number(data.profit.usd);
        return acc;
      }, {}) ?? {};

    const sumBalanceData =
      userData?.reduce<Record<string, any>>((acc, data) => {
        if (!acc[data.telegram_id]) {
          acc[data.telegram_id] = { balance: 0, commission: 0, credits: 0 };
        }

        acc[data.telegram_id].balance += Number(data.balance) || 0;
        acc[data.telegram_id].commission += Number(data.commission) || 0;
        acc[data.telegram_id].credits += Number(data.credits) || 0;

        totalPolBalance += Number(data.balance) || 0;
        totalCreditBalance += Number(data.credits) || 0;
        totalCommissionBalance += Number(data.commission) || 0;
        return acc;
      }, {}) ?? {};

    fetchedNodes.map((n) => {
      const payout =
        (sumDepositData[n.id] || 0) - (sumProfitData[n.id] || 0) / 2;
      n.data.leaderPayout = payout;
      totalLeaderPayout += payout;
      totalDeposit += sumDepositData[n.id] || 0;
      totalProfit += sumProfitData[n.id] || 0;
      console.log(
        `${n.id} payout: ${payout} = ${sumDepositData[n.id] || 0} - ${
          sumProfitData[n.id] || 0
        }`
      );

      // set owner leaderpayout
      if (n.id === telegramId) {
        setUserLeaderPayout(payout);
      }

      return n;
    });

    console.log("telegramIds: ", telegramIds);
    console.log("depositData: ", depositData);
    console.log("tradeData: ", tradeData);
    console.log("userData: ", userData);

    console.log("sumDepositData: ", sumDepositData);
    console.log("sumProfitData: ", sumProfitData);

    setTotalLeaderPayout(totalLeaderPayout);
    setTotalDepositPayout(totalDeposit);
    setTotalProfitPayout(totalProfit);

    return {
      totalPolBalance,
      totalCreditBalance,
      totalCommissionBalance,
      totalLeaderPayout,
    };
  };

  // const calculateBalances = async (telegramId: string) => {
  //   const { data, error } = await supabase
  //     .from("Users")
  //     .select("balance, credits, commission")
  //     .eq("telegram_id", telegramId)
  //     .single();

  //   if (error) {
  //     // toast.error("Error calculating Hierarchy Summary!");
  //     return {
  //       polygon: 0,
  //       credit: 0,
  //       commission: 0,
  //     };
  //   }

  //   return {
  //     polygon: data?.balance,
  //     credit: data?.credits,
  //     commission: data?.commission,
  //   };
  // };

  // Run bfs to construct tree structure
  const getHierarchyData = async () => {
    setIsGraphLoading(true);

    const rootNode = {
      id: telegramId!,
      type: "customNode",
      position: { x: 0, y: 0 },
      data: { label: telegramId!, leaderPayout: 0 },
    };

    const fetchedNodes = [rootNode];
    const fetchedEdges = [];

    const queue = [{ id: telegramId, x: 0, y: 0, level: 0 }];

    while (queue.length > 0) {
      // Process one level at a time
      const currentLevel = queue[0].level;
      const currentLevelNodes = queue.filter(
        (item) => item.level === currentLevel
      );

      // Remove nodes at this level from the queue to process the next level
      currentLevelNodes.forEach((node) => {
        const index = queue.indexOf(node);
        if (index > -1) queue.splice(index, 1);
      });

      // Collect all children for this entire level
      let childrenToPlace: { parentId: string; childId: string[] }[] = [];
      for (const parentNode of currentLevelNodes) {
        const children = await getChildren(parentNode.id!);
        if (children.length > 0) {
          childrenToPlace.push({
            parentId: parentNode.id!,
            childId: children,
          });
        }
      }

      // Skip if no children at this level
      if (childrenToPlace.length === 0) {
        continue;
      }

      const nextLevel = currentLevel + 1;
      const newNodes: { id: string; x: number; y: number; parentId: string }[] =
        [];

      for (const { parentId, childId } of childrenToPlace) {
        childId.forEach((cId) => {
          newNodes.push({ id: cId, x: 0, y: 0, parentId });
        });
      }

      // Update nodes and edges
      for (const node of newNodes) {
        fetchedNodes.push({
          id: node.id,
          type: "customNode",
          position: {
            x: 0,
            y: 0,
          },
          data: { label: node.id, leaderPayout: 0 },
        });
        fetchedEdges.push({
          id: `e${node.parentId}-${node.id}`,
          source: node.parentId,
          target: node.id,
        });

        // Add to queue for next level
        queue.push({ id: node.id, x: 0, y: 0, level: nextLevel });
      }
    }

    const userIds = fetchedNodes.map((node) => node.id!);
    // Calculate payout and summary
    const { totalPolBalance, totalCommissionBalance, totalCreditBalance } =
      await calculateLeaderPayoutAndBalance(userIds, fetchedNodes);

    // Position the nodes
    runDagreLayout(fetchedNodes as NodeType[], fetchedEdges);

    setHierarchySummary({
      numberOfChild: fetchedNodes.length - 1,
      polBalance: totalPolBalance,
      commissionBalance: totalCommissionBalance,
      creditBalance: totalCreditBalance,
    });
    setIsGraphLoading(false);
  };

  const runDagreLayout = (nodes: NodeType[], edges: EdgeType[]) => {
    const g = new dagre.graphlib.Graph();
    g.setGraph({ rankdir: "TB", ranksep: 200, nodesep: 100 });
    g.setDefaultEdgeLabel(() => ({}));

    // Set every node width and height
    nodes.forEach((node) => {
      g.setNode(node.id, { width: 180, height: 100 });
    });

    edges.forEach((edge) => {
      g.setEdge(edge.source, edge.target);
    });

    // Initialize layout
    dagre.layout(g);

    // Now map back positions
    const newNodes = nodes.map((node) => {
      const coords = g.node(node.id);
      return {
        ...node,
        position: {
          x: coords.x,
          y: coords.y,
        },
      };
    });

    setNodes(newNodes);
    setEdges(edges);
  };

  const getCommission = async () => {
    // setIsLoading(true);
    // change to 'to' (currently set to from just for displaying data)
    const { data } = await supabase
      .from("Commission")
      .select("*")
      .eq("to", telegramId)
      .order("created_at", { ascending: false });

    if (data) {
      const reorderedData: CommissionType[] = data.map((d, i) => {
        const temp = {
          id: i + 1,
          from: d?.from,
          amount: d?.amount.toFixed(4),
          level: d?.level,
          isPaid: d?.isPaid,
          created_at: d?.created_at,
        };
        return temp;
      });
      setTrades(reorderedData);
    }
    // setIsLoading(false);
    return null;
  };

  const getTrades = async () => {
    // setIsLoading(true);

    const { data } = await supabase
      .from("Transaction")
      .select("*")
      .eq("telegram_id", telegramId)
      .order("created_at", { ascending: false });

    if (data) {
      const reorderedData: TradeType[] = data.map((d, i) => {
        const temp = {
          id: i + 1,
          profitUsd: d?.profit?.usd.toFixed(2),
          profitPol: d?.profit?.matic.toFixed(2),
          oldBalance: d?.balance?.old.toFixed(2),
          newBalance: d?.balance?.new.toFixed(2),
          oldCredit: d?.credit?.old.toFixed(2),
          newCredit: d?.credit?.new.toFixed(2),
          created_at: d?.created_at,
        };
        return temp;
      });
      setTrades(reorderedData);
      const profitUsd = reorderedData.reduce(
        (acc, trade) => acc + (parseFloat(trade.profitUsd) || 0),
        0
      );
      const profitPol = reorderedData.reduce(
        (acc, trade) => acc + (parseFloat(trade.profitPol) || 0),
        0
      );
      setProfitUsd(profitUsd);
      setProfitPol(profitPol);
    }
    // setIsLoading(false);
    return null;
  };

  const getDeposit = async () => {
    // setIsLoading(true);

    const { data } = await supabase
      .from("Deposits")
      .select("*")
      .eq("telegram_id", telegramId)
      .order("created_at", { ascending: false });

    if (data) {
      const reorderedData: DepositType[] = data.map(
        (d: DepositType, i: number) => {
          const temp = {
            id: i + 1,
            address_bsc: d?.address_bsc,
            address_trc: d?.address_trc,
            network: d?.network,
            currency: d?.currency,
            requested_amount: d?.requested_amount,
            actual_amount: d?.actual_amount,
            hash: d?.hash,
            status: d?.status,
            remark: d?.remark,
            created_at: d?.created_at,
          };
          return temp;
        }
      );
      setTrades(reorderedData);
    }
    // setIsLoading(false);

    return null;
  };

  const getWithdraws = async () => {
    // setIsLoading(true);

    const { data } = await supabase
      .from("Withdraws")
      .select("*")
      .eq("telegram_id", telegramId)
      .order("created_at", { ascending: false });

    if (data) {
      const reorderedData: WithdrawType[] = data.map((d, i) => {
        const temp = {
          id: i + 1,
          address: d?.address,
          network: d?.network,
          currency: d?.currency,
          amount: d?.amount.toFixed(2),
          hash: d?.hash,
          status: d?.status,
          remark: d?.remark,
          created_at: d?.created_at,
        };
        return temp;
      });
      setTrades(reorderedData);
    }
    // setIsLoading(false);

    return null;
  };

  const getUser = async () => {
    const { data, error } = await supabase
      .from("Users")
      .select(
        "telegram_id, username, balance, commission, credits, referral_code, parent, created_at, level, tier, license, dailyProfit, dailyTrades, address_bsc, address_trc, pol_address, usdt_history, token_history, activated, ban"
      )
      .eq("telegram_id", telegramId)
      .single();

    if (error) {
      toast.error("Error getting user information!");
      return;
    }

    setUser(data);
  };

  const handleCheckboxChange = () => {
    setExcludeUser((prev) => {
      const isChecked = !prev;
      setHierarchySummary((prevSummary) => ({
        ...prevSummary,
        polBalance: isChecked
          ? prevSummary.polBalance - (user?.balance || 0)
          : prevSummary.polBalance + (user?.balance || 0),
        commissionBalance: isChecked
          ? prevSummary.commissionBalance - (user?.commission || 0)
          : prevSummary.commissionBalance + (user?.commission || 0),
        creditBalance: isChecked
          ? prevSummary.creditBalance - (user?.credits || 0)
          : prevSummary.creditBalance + (user?.credits || 0),
      }));

      setTotalLeaderPayout((prevPayout) =>
        isChecked
          ? prevPayout - (userLeaderPayout || 0)
          : prevPayout + (userLeaderPayout || 0)
      );
      return isChecked;
    });
  };

  useEffect(() => {
    getUser();
    getHierarchyData();
  }, []);

  useEffect(() => {
    getHierarchyData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (tableType === "TRADE") {
      getTrades();
    }
    if (tableType === "COMMISSION") {
      getCommission();
    }
    if (tableType === "DEPOSIT") {
      getDeposit();
    }
    if (tableType === "WITHDRAW") {
      getWithdraws();
    }
  }, [tableType]);

  const tabItems = ["TRADE", "DEPOSIT", "WITHDRAW", "COMMISSION", "HIERARCHY"];

  return (
    <div className="w-screen h-fit p-6 flex justify-center">
      <div className="w-4/5 h-fit bg-pureWhite rounded-md">
        <div className="relative pb-2 mb-6">
          <div className="p-4 gap-2">
            <span className="text-xl font-medium">Manage User</span>
          </div>
          <div className="absolute bottom-0 left-0 w-full border-b border-solid border-slate opacity-40"></div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-4/5 flex flex-col gap-6">
            {/* Basic User Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">User Information</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <span className="font-semibold">Telegram ID:</span>
                  <span className="text-slate">{telegramId || "-"}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Username:</span>
                  <span className="text-slate">{user?.username || "-"}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Referral Code:</span>
                  <span className="text-slate">
                    {user?.referral_code || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* Wallet Addresses */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Wallet Addresses</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 flex-wrap">
                  <span className="font-semibold">POL:</span>
                  <span className="text-slate break-words overflow-hidden">
                    {user?.pol_address || "-"}
                  </span>
                </div>
                <div className="flex gap-2 flex-wrap">
                  <span className="font-semibold">BSC:</span>
                  <span className="text-slate break-words overflow-hidden">
                    {user?.address_bsc || "-"}
                  </span>
                </div>
                <div className="flex gap-2 flex-wrap">
                  <span className="font-semibold">TRC:</span>
                  <span className="text-slate break-words overflow-hidden">
                    {user?.address_trc || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* Financial Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Financial Information</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <span className="font-semibold">Credit Balance:</span>
                  <span className="text-slate text-wrap">
                    {user?.credits.toFixed(2) || 0} USDT
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Polygon Balance:</span>
                  <span className="text-slate text-wrap">
                    {user?.balance.toFixed(2) || 0} POL
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Commission Balance:</span>
                  <span className="text-slate text-wrap">
                    {user?.commission.toFixed(2) || 0} USDT
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Total USD Profit:</span>
                  <span className="text-slate text-wrap">
                    {profitUsd.toFixed(2)} USD
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Total POL Profit:</span>
                  <span className="text-slate text-wrap">
                    {profitPol.toFixed(2)} POL
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* Hierarchy and Account Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Account Information</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <span className="font-semibold">Tier:</span>
                  <span className="text-slate text-wrap">
                    {user?.tier || "-"}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Hierarchy Level:</span>
                  <span className="text-slate text-wrap">
                    {user?.level || "-"}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Parent Telegram ID:</span>
                  <span
                    className={`text-slate text-wrap ${
                      user?.parent && "cursor-pointer hover:text-red"
                    }`}
                    onClick={() => {
                      if (user?.parent) {
                        navigate(`/users/${user?.parent}`);
                        window.location.reload();
                      }
                    }}
                  >
                    {user?.parent || "-"}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Activated:</span>
                  <span className="text-slate text-wrap">
                    {user?.activated ? "Yes" : "No"}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Banned:</span>
                  <span className="text-slate text-wrap">
                    {user?.ban ? "Yes" : "No"}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* Activity and Historical Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Activity & History</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <span className="font-semibold">Daily Trades:</span>
                  <span className="text-slate text-wrap">
                    {user?.dailyTrades || 0}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Daily Profit:</span>
                  <span className="text-slate text-wrap">
                    {user?.dailyProfit.toFixed(2) || 0} USD
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Token History:</span>
                  <span className="text-slate text-wrap">
                    {user?.token_history || 0}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">USDT History:</span>
                  <span className="text-slate text-wrap">
                    {user?.usdt_history || 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* IMPORTANT DATES */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Important Dates</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <span className="font-semibold">Joined Date:</span>
                  <span className="text-slate text-wrap">
                    {user &&
                      format(
                        new Date(user.created_at),
                        "dd MMM yyyy, HH:mm:ss"
                      )}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">License Expiry:</span>
                  <span className="text-slate text-wrap">
                    {user &&
                      format(new Date(user.license), "dd MMM yyyy, HH:mm:ss")}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* HIERARCHY SUMMARY */}
            <div className="flex flex-col gap-4 relative">
              <h2 className="text-xl font-medium">Hierarchy Summary</h2>
              <div className="flex gap-2 mb-3 absolute right-0 top-0">
                <DateCalendar
                  label="Start Date"
                  setDate={setStartDate}
                  date={startDate}
                />
                <DateCalendar
                  label="End Date"
                  setDate={setEndDate}
                  date={endDate}
                  startDate={startDate}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center h-[25px]">
                  <span className="font-semibold">Leader's Payout:</span>
                  <ClipLoader
                    color="red"
                    loading={isGraphLoading}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  {!isGraphLoading && (
                    <div className="flex justify-center items-center">
                      <span className="text-slate text-wrap">
                        {totalLeaderPayout.toFixed(2) || 0}
                      </span>
                      <Tooltip
                        title={
                          <>
                            <strong>Leader Payout</strong> = Total Deposit -
                            Total Profit / 2 <br />
                            <strong>{totalLeaderPayout.toFixed(2)} = </strong>
                            {totalDepositPayout.toFixed(2)} -{" "}
                            {totalProfitPayout.toFixed(2)} / 2
                          </>
                        }
                      >
                        <IconButton>
                          <InformationIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Number of Child:</span>
                  <span className="text-slate text-wrap">
                    {hierarchySummary?.numberOfChild || 0}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Total Polygon Balance:</span>
                  <span className="text-slate text-wrap">
                    {(hierarchySummary?.polBalance || 0).toFixed(4)} POL
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Total Credit Balance:</span>
                  <span className="text-slate text-wrap">
                    {(hierarchySummary?.creditBalance || 0).toFixed(2)} USDT
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">
                    Total Commission Balance:
                  </span>
                  <span className="text-slate text-wrap">
                    {(hierarchySummary?.commissionBalance || 0).toFixed(2)} USDT
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="checkbox"
                    className="w-4 h-4 rounded"
                    checked={excludeUser}
                    onChange={handleCheckboxChange}
                  />
                  <span className="italic text-watermelon font-medium">
                    exclude Telegram ID: {telegramId}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* TRANSACTION TABLE */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Transaction History</h2>
              <div className="w-full mb-4">
                <div className="flex gap-2 p-2 bg-white rounded-md w-fit mb-3">
                  {tabItems.map((item, index) => (
                    <button
                      key={`${telegramId}-${index}`}
                      className={`duration-300 hover:bg-pink hover:text-white rounded-md 
                          p-1 bg-red-200 text-slate px-2 
                          ${
                            tableType === item
                              ? "text-white bg-red hover:bg-red"
                              : ""
                          }`}
                      onClick={() => {
                        setTableType(item);
                      }}
                      disabled={item === "HIERARCHY" && isGraphLoading}
                    >
                      {item.charAt(0) + item.slice(1).toLowerCase()}
                    </button>
                  ))}
                </div>

                {tableType !== "HIERARCHY" ? (
                  <DataTable tableType={tableType} trades={trades} />
                ) : (
                  <Hierarchy
                    nodes={nodes}
                    edges={edges}
                    setNodes={setNodes}
                    setEdges={setEdges}
                    isLoading={isGraphLoading}
                  />
                )}
              </div>
              <div className="w-full border-b border-solid border-slate opacity-40"></div>

              {/* Actions */}
              <div className="flex gap-4 mb-10">
                <button
                  onClick={() => navigate("/users")}
                  className="py-2 px-6 rounded-md bg-blue text-white duration-200 hover:opacity-80 hover:shadow-md"
                >
                  Back
                </button>
                {role === "SUPER_ADMIN" && (
                  <button
                    onClick={() => navigate(`/users/${telegramId}/edit`)}
                    className="py-2 px-6 rounded-md bg-watermelon text-white duration-200 hover:opacity-80 hover:shadow-md"
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetail;
