import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { handleAuth } from "../hooks/handleAuth";

type PrivateRouteProps = {
  children: ReactNode;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleAuth().then((authenticated) => {
      setIsAuthenticated(authenticated);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <ClipLoader
          color="#6c5ce7"
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
