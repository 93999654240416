import React from "react";
import {
  commissionColumns,
  commissionHomeColumns,
  dataGridProps,
  depositColumns,
  depositHomeColumns,
  tradeColumns,
  tradeHomeColumns,
  withdrawColumns,
  withdrawPendingColumns,
} from "./UserTransactionColumns";
import { HistoryIcon } from "./Icons";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Paper } from "@mui/material";

interface TableConfig {
  type: string;
  title: string;
  columns: any[];
}

const DataTable: React.FC<{
  tableType: string;
  trades: any[];
  dataGridProps?: object;
  isCheckbox?: boolean;
  isPending?: boolean;
  selectedRows?: string[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>;
  handleRemarkChange?: any;
}> = ({
  tableType,
  trades,
  isCheckbox,
  isPending,
  selectedRows,
  setSelectedRows,
  handleRemarkChange,
}) => {
  const withdrawPendingColumnsWithRemark: GridColDef[] = [
    ...withdrawPendingColumns,
    {
      field: "remark",
      headerName: "Remark",
      width: 300,
      renderCell: (param) => {
        const { row } = param;
        return (
          <input
            className="p-2 h-full w-full"
            placeholder="Add your remark..."
            onChange={(e) => handleRemarkChange(row.id, e.currentTarget.value)}
          />
        );
      },
    },
  ];

  const tableConfigs: Record<string, TableConfig> = {
    TRADE: {
      type: "TRADE",
      title: "Trade History",
      columns: tradeColumns,
    },
    COMMISSION: {
      type: "COMMISSION",
      title: "Commission History",
      columns: commissionColumns,
    },
    DEPOSIT: {
      type: "DEPOSIT",
      title: "Deposit History",
      columns: depositColumns,
    },
    WITHDRAW: {
      type: "WITHDRAW",
      title: "Withdraw History",
      columns: withdrawColumns,
    },
    WITHDRAW_HOME: {
      type: "WITHDRAW",
      title: "Withdraw History",
      columns: isPending
        ? withdrawPendingColumnsWithRemark
        : withdrawPendingColumns,
    },
    DEPOSIT_HOME: {
      type: "DEPOSIT",
      title: "Deposit History",
      columns: depositHomeColumns,
    },
    TRADE_HOME: {
      type: "TRADE",
      title: "Trade History",
      columns: tradeHomeColumns,
    },
    COMMISSION_HOME: {
      type: "COMMISSION",
      title: "Commission History",
      columns: commissionHomeColumns,
    },
  };

  const currentConfig = tableConfigs[tableType];

  return (
    <>
      <div className="font-semibold text-xl mb-4 text-red flex items-center gap-2">
        <span>{currentConfig.title}</span>
        <HistoryIcon />
      </div>
      <Paper sx={{ height: 800, width: "100%", boxShadow: 3 }}>
        <DataGrid
          columns={currentConfig.columns}
          rows={trades}
          {...dataGridProps}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          aria-hidden="true"
          pageSizeOptions={[20, 50, 100, 200]}
          checkboxSelection={isCheckbox}
          rowSelectionModel={selectedRows} // Bind selected rows to the state
          onRowSelectionModelChange={(newRowSelectionModel) => {
            if (setSelectedRows) {
              setSelectedRows(newRowSelectionModel as string[]);
            }
          }}
        />
      </Paper>
    </>
  );
};

export default DataTable;
