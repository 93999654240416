import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { supabase } from "../supabase/supabaseClient";
import { fetchWalletBalance } from "../hooks/fetchWalletBalance";
import { processWithdraw } from "../hooks/processWithdraw";
import { useDispatch } from "react-redux";
import ConfirmPopup from "../components/ConfirmPopup";
import { open } from "../redux/confirmPopupSlice";
import WalletInfo from "../components/WalletInfo";
import DataTable from "../components/DataTable";
import { dataGridProps } from "../components/UserTransactionColumns";
import {
  CommissionRowType,
  DepositRowType,
  RemarksType,
  TradeRowType,
  WithdrawRowType,
} from "../models/AdminHomeTransaction.model";
import { handleAuth } from "../hooks/handleAuth";
import { json2csv } from "json-2-csv";

type DataRowState = {
  DEPOSIT: DepositRowType[];
  WITHDRAW: WithdrawRowType[];
  TRADE: TradeRowType[];
  COMMISSION: CommissionRowType[];
};

type TableType = "DEPOSIT" | "WITHDRAW" | "TRADE" | "COMMISSION";

export const Home = () => {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [isPending, setIsPending] = useState<boolean>(true);
  const [isAuthValid, setIsAuthValid] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [totalWithdrawal, setTotalWithdrawal] = useState<number>(0);
  const [dataRow, setDataRow] = useState<DataRowState>({
    DEPOSIT: [],
    WITHDRAW: [],
    TRADE: [],
    COMMISSION: [],
  });
  const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false);
  const [wallet, setWallet] = useState({
    bsc_usdt_balance: 0,
    tron_usdt_balance: 0,
    total_usdt_balance: 0,
    bnb_gas_balance: 0,
    trx_gas_balance: 0,
    total_gas_balance: 0,
    bnb_gas_balance_usd: 0,
    trx_gas_balance_usd: 0,
  });
  const [remarks, setRemarks] = useState<RemarksType>({});
  const [isRemarksLoading, setIsRemarksLoading] = useState<boolean>(false);
  const [tableType, setTableType] = useState<TableType>("WITHDRAW");

  const getWalletBalance = async () => {
    const walletData = await fetchWalletBalance();
    setWallet(walletData);
  };

  const getTotalAvailableBalance = useCallback(() => {
    let sum = 0;

    if (tableType === "WITHDRAW") {
      const withdawData = dataRow[tableType];
      withdawData
        ?.filter((d) => selectedRows.includes(d?.id))
        .map((d) => (sum += d?.amount));
    }

    setTotalWithdrawal(sum);
  }, [selectedRows, tableType]);

  const getDeposits = useCallback(async () => {
    if (!isAuthValid) return;

    const { data, error } = await supabase
      .from("Deposits")
      .select(
        "id, telegram_id, hash, address, actual_amount, currency, network, created_at"
      )
      .eq("status", "success")
      .order("created_at", { ascending: false });

    if (error) {
      toast.error("Error getting deposits");
      return;
    }
    setDataRow((prev) => ({
      ...prev,
      DEPOSIT: data as DepositRowType[],
    }));
  }, [isAuthValid]);

  const getWithdraws = useCallback(async () => {
    if (!isAuthValid) return;
    try {
      let query = supabase
        .from("Withdraws")
        .select("*")
        .order("created_at", { ascending: false });

      // select only USDT
      query = query.eq("currency", "USDT");

      if (isPending) {
        query = query.is("status", null);
      } else {
        query = query.eq("status", "success");
      }

      const { data, error } = await query;

      if (error) {
        toast.error("Error getting withdraws");
        return;
      }
      setDataRow((prev) => ({
        ...prev,
        WITHDRAW: data,
      }));
    } catch (e) {
      toast.error("Error getting withdraws!");
    }
  }, [isAuthValid, isPending]);

  const getTrades = useCallback(async () => {
    if (!isAuthValid) return;

    try {
      const { data, error } = await supabase
        .from("Transaction")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) {
        toast.error("Error getting trades!");
        return;
      }
      setDataRow((prev) => ({
        ...prev,
        TRADE: data,
      }));
    } catch (e) {
      toast.error("Error getting trades!");
    }
  }, [isAuthValid]);

  const getCommissions = useCallback(async () => {
    if (!isAuthValid) return;

    try {
      const { data, error } = await supabase
        .from("Commission")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) {
        toast.error("Error getting trades!");
        return;
      }
      setDataRow((prev) => ({
        ...prev,
        COMMISSION: data,
      }));
    } catch (e) {
      toast.error("Error getting trades!");
    }
  }, [isAuthValid]);

  const handleApproveClick = () => {
    dispatch(open({ actionType: "APPROVE" }));
  };

  const handleApprove = async () => {
    if (!isAuthValid) return;

    if (totalWithdrawal > wallet.total_usdt_balance!) {
      toast.error("Insufficient Amount");
      return;
    }
    await processWithdraw(selectedRows, setIsApproveLoading);

    setSelectedRows([]);
    getWithdraws();
    toast.success("Withdraw Successful!");
  };

  const handleRemarkChange = (id: number, value: string) => {
    setRemarks((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleRejectClick = () => {
    dispatch(open({ actionType: "REJECT" }));
  };

  const handleReject = async () => {
    if (!isAuthValid) return;

    setIsRemarksLoading(true);
    const updates = Object.entries(remarks).map(([id, remark]) => ({
      id: parseInt(id),
      status: "rejected",
      remark,
    }));

    const { error } = await supabase
      .from("Withdraws")
      .upsert(updates, { onConflict: "id" });

    if (error) {
      toast.error("Error updating remarks");
    } else {
      toast.success("Remarks updated successfully!");
      setRemarks({});
      getWithdraws();
    }

    setSelectedRows([]);
    setIsRemarksLoading(false);
  };

  const handleCsv = useCallback(() => {
    const csv = json2csv(dataRow[tableType]);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${tableType}-data.csv`);
    link.click();
  }, [tableType, dataRow]);

  useEffect(() => {
    handleAuth().then((isValid) => setIsAuthValid(isValid));
  }, []);

  useEffect(() => {
    getTotalAvailableBalance();
    getWithdraws();
    getWalletBalance();
  }, [isAuthValid]);

  useEffect(() => {
    // to prevent refetching everytime tab changes
    if (tableType === "WITHDRAW" && dataRow.WITHDRAW.length === 0) {
      getWithdraws();
    } else if (tableType === "DEPOSIT" && dataRow.DEPOSIT.length === 0) {
      getDeposits();
    } else if (tableType === "TRADE" && dataRow.TRADE.length === 0) {
      getTrades();
    } else if (tableType === "COMMISSION" && dataRow.COMMISSION.length === 0) {
      getCommissions();
    }
  }, [tableType]);

  useEffect(() => {
    getWithdraws();
  }, [isPending]);

  useEffect(() => {
    getTotalAvailableBalance();
  }, [selectedRows]);

  const tabType = ["WITHDRAW", "DEPOSIT", "TRADE", "COMMISSION"];

  return (
    <div className="p-4 flex justify-center items-center flex-col">
      <ConfirmPopup onApprove={handleApprove} onReject={handleReject} />
      <WalletInfo />
      <div className="w-4/5 mb-4 flex justify-between items-center">
        <div className="flex gap-2 p-2 bg-pureWhite rounded-md w-fit">
          {tabType.map((t, idx) => (
            <span
              key={`tab-${idx}`}
              className={`hover:bg-pink hover:text-white rounded-md p-1 bg-red-200 text-slate cursor-pointer px-2 ${
                tableType === t && "text-white bg-red hover:bg-red"
              }`}
              onClick={() => {
                setTableType(t as TableType);
                setSelectedRows([]);
              }}
            >
              {t.slice(0, 1) + t.slice(1).toLowerCase()}
            </span>
          ))}
        </div>
        <div>
          <button
            onClick={handleCsv}
            className="bg-red px-3 py-2 text-white rounded-full"
          >
            Export to CSV
          </button>
        </div>
      </div>
      <div className="p-4 rounded-md w-4/5 bg-pureWhite">
        {tableType === "WITHDRAW" && (
          <div className="w-full flex justify-between my-3">
            <div className="flex gap-2 h-[35px]">
              <span
                onClick={() => {
                  setIsPending(true);
                  setSelectedRows([]);
                  setTotalWithdrawal(0);
                }}
                className={`px-1.5 flex justify-center items-center ${
                  !isPending && "hover:bg-slate-200"
                } transition-all font-semibold duration-300 rounded-md cursor-pointer ${
                  isPending && "bg-red text-white"
                }`}
              >
                Pending
              </span>
              <span
                onClick={() => {
                  setIsPending(false);
                  setSelectedRows([]);
                  setTotalWithdrawal(0);
                }}
                className={`px-1.5 flex justify-center items-center ${
                  isPending && "hover:bg-slate-200"
                } transition-all font-semibold duration-300 rounded-md cursor-pointer ${
                  !isPending && "bg-red text-white"
                }`}
              >
                Completed
              </span>
            </div>
            <div className="h-[20px]">
              {isPending && role === "SUPER_ADMIN" && (
                <div className="flex gap-2">
                  <button
                    onClick={handleRejectClick}
                    disabled={isRemarksLoading || selectedRows.length < 1}
                    className="bg-red p-2 px-2.5 rounded-full text-white font-semibold hover:opacity-70 transition-all duration-300 w-[85px]"
                  >
                    {isRemarksLoading ? (
                      <span>
                        <ClipLoader
                          color="white"
                          loading={isRemarksLoading}
                          size={15}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </span>
                    ) : (
                      "Reject"
                    )}
                  </button>
                  <button
                    disabled={isApproveLoading || selectedRows.length < 1}
                    onClick={handleApproveClick}
                    className="bg-red p-2 px-2.5 rounded-full text-white font-semibold hover:opacity-70 transition-all duration-300 w-[85px]"
                  >
                    {isApproveLoading ? (
                      <span>
                        <ClipLoader
                          color="white"
                          loading={isApproveLoading}
                          size={15}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </span>
                    ) : (
                      "Approve"
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <div style={{ height: 850, width: "100%" }}>
          <DataTable
            tableType={tableType + "_HOME"}
            trades={dataRow[tableType]}
            dataGridProps={dataGridProps}
            isCheckbox={true}
            isPending={isPending}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            handleRemarkChange={handleRemarkChange}
          />
        </div>
        <div className="mt-4 h-[30px] relative">
          {isPending && tableType === "WITHDRAW" && (
            <span className="font-semibold text-lg text-slate sm:text-base">
              Total Withdrawl Amount: {totalWithdrawal} USD
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
